// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-initiative-js": () => import("/opt/build/repo/src/templates/initiative.js" /* webpackChunkName: "component---src-templates-initiative-js" */),
  "component---src-pages-10-practices-js": () => import("/opt/build/repo/src/pages/10practices.js" /* webpackChunkName: "component---src-pages-10-practices-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-onlineresources-js": () => import("/opt/build/repo/src/pages/onlineresources.js" /* webpackChunkName: "component---src-pages-onlineresources-js" */)
}

